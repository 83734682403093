import React from "react";
import { string, number } from "prop-types";

const TwitterIcon = ({ color = "#fff", width = 20, height = 17 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 17">
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.168.304a7.951 7.951 0 0 1-2.57 1.004A3.989 3.989 0 0 0 13.647 0c-2.232 0-4.043 1.857-4.043 4.147 0 .325.035.64.104.944-3.36-.173-6.34-1.822-8.336-4.334a4.222 4.222 0 0 0-.547 2.087c0 1.438.713 2.708 1.8 3.451A3.97 3.97 0 0 1 .79 5.778v.05c0 2.01 1.394 3.688 3.246 4.067a3.94 3.94 0 0 1-1.827.073c.515 1.647 2.007 2.846 3.777 2.878A7.986 7.986 0 0 1 0 14.566a11.257 11.257 0 0 0 6.2 1.86c7.439 0 11.506-6.317 11.506-11.798 0-.18-.003-.36-.01-.537a8.296 8.296 0 0 0 2.016-2.146 7.92 7.92 0 0 1-2.322.652A4.139 4.139 0 0 0 19.168.304"
    />
  </svg>
);

TwitterIcon.propTypes = {
  color: string,
  width: number,
  height: number
};

export default TwitterIcon;

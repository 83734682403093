export function setUserLoggedIn(isUserLoggedIn, user) {
  return {
    type: "USER_LOGGED_IN",
    payload: {
      isUserLoggedIn,
      user
    }
  };
}

// Action to enable/disable to loading indicator
export function setLoading(isLoading) {
  if (isLoading) {
    return { type: "QT-PAGE_LOADING" };
  } else {
    return { type: "QT-PAGE_FINISHED_LOADING" };
  }
}

// Action to store all the authors in redux
export function setAuthors(authors) {
  return {
    type: "AUTHOR_LIST",
    payload: authors
  };
}

export function setEntities(list) {
  return {
    type: "ENTITY_LIST",
    payload: list
  };
}

export function setPublicationStories(key, value) {
  return {
    type: "SECTION_PUBLICATION_STORIES",
    payload: { key, value }
  };
}

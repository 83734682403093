import wretch from "wretch";

// // Sign-In User
// export function login(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/member/login")
//     .post(body)
//     .unauthorized(() => Promise.reject(new Error("Invalid username or password")))
//     .json(res => Promise.resolve(res));
// }

// // Sign-Up User
// export function register(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/member")
//     .post(body)
//     .json(res => Promise.resolve(res))
//     .catch(ex => Promise.reject(ex));
// }

// Send the email with OTP for verification
export function verifyEmail(email) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/v1/members/verification-email")
    .post({ member: { email } })
    .json(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// // Send the email with OTP for verification for resetting the password
// export function forgotPassword(email) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/member/forgot-password")
//     .post({ email })
//     .res(() => Promise.resolve())
//     .catch(ex => Promise.reject(ex));
// }

// // Reset the password
// export function resetPassword(token, password, repeatPassword) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/member/password")
//     .post({
//       token,
//       password,
//       "repeat-password": repeatPassword
//     })
//     .json(res => Promise.resolve(res))
//     .catch(ex => Promise.reject(ex));
// }

// Verify the email by validating OTP
export function verifyEmailOtp(otp, id) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/api/v1/members/${id}`)
    .patch({
      otp: parseInt(otp),
      login: true,
      member: {
        "verification-status": "email"
      }
    })
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Returns the current User
export function getUser() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/v1/members/me")
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Logout the User
export function logout() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/logout")
    .get()
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// Get Entities of type 'publication-type'
export function getPublicationEntities() {
  return wretch()
    .url("/api/v1/entities")
    .query({ type: "publication-type", limit: 200 }) // setting 200 as max-limit
    .get()
    .json(response => response.entities);
}

export function getEntities() {
  return wretch()
    .url("/api/v1/entities")
    .query({ limit: 600 }) // setting 600 as max-limit
    .get()
    .json(response => response.entities);
}

// Get Entities of type 'publication-year'
export function getPublicationEntitiesYear() {
  return wretch()
    .url("/api/v1/entities")
    .query({ type: "publication-year", limit: 200 }) // setting 200 as max-limit
    .get()
    .json(response => response.entities);
}

// Get Entity object
export function getEntity(id) {
  return wretch()
    .url(`/api/v1/entities/${id}`)
    .get()
    .json(response => response.entity);
}

// Get all the authors
export function getAllAuthors() {
  return wretch()
    .url("/api/v1/authors")
    .query({ limit: 1 })
    .get()
    .json(response => {
      return wretch()
        .url("/api/v1/authors")
        .query({ limit: response.page.total })
        .get()
        .json(response => response.authors);
    });
}

// Get all stories of particular section
export function getSectionStories(sectionId, limit, offset, template = "publication") {
  const fields =
    "author-name,headline,slug,subheadline,sections,hero-image-metadata,id,hero-image-s3-key,url,authors,author-id,story-template,metadata,cards,access";

  return wretch()
    .url("/api/v1/stories")
    .query({ "section-id": sectionId, template, limit, offset, fields })
    .get()
    .json(response => response.stories);
}

// Get Author Stories
export function getAuthorStories(authorId, limit, offset) {
  return wretch()
    .url(`/api/v1/authors/${authorId}/collection`)
    .query({ limit, offset })
    .get()
    .json(response => response.items);
}

// Get Stories by Attributes
export function getStoriesByAttribute(type, offset, fields) {
  return wretch()
    .url(`/api/v1/stories?story-attributes.${type}`)
    .query({ offset, fields })
    .get()
    .json(response => response.stories);
}

// Story Search
export function searchStories(params) {
  return wretch()
    .url("/api/v1/search")
    .query(params)
    .get()
    .json(response => response.results.stories);
}

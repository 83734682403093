import React from "react";
import { connect } from "react-redux";
import { string, object } from "prop-types";
import get from "lodash/get";

import SocialShareIcons from "../../social-icons";

import "./footer.m.css";

const FooterBase = ({ copyrightText, socialLinks }) => (
  <div styleName="wrapper">
    <div className="container">
      <div styleName="copyright-social">
        <div styleName="copyright-text">
          <p styleName="copyright-text">{copyrightText} All rights reserved.</p>
          <a href="https://www.quintype.com/" target="_blank" rel="noopener noreferrer">
            Powered by Quintype
          </a>
        </div>

        {socialLinks && (
          <div styleName="follow-us">
            <span styleName="follow-us-text">Follow us on</span>
            <SocialShareIcons />
          </div>
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = state => {
  return {
    copyrightText: get(state, ["qt", "config", "publisher-settings", "copyright"], "Copyright© 2019"),
    socialLinks: get(state, ["qt", "config", "social-links"], {})
  };
};

FooterBase.propTypes = {
  copyrightText: string,
  socialLinks: object
};

const FooterRow = connect(mapStateToProps)(FooterBase);

export default FooterRow;

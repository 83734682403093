export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  BLOG_PAGE: "blog-page",
  PHD_PAGE: "phd-page",
  PEOPLE_PAGE: "people-page",
  VISUAL_STORY_PAGE: "visual-story",
  AUTHOR_PAGE: "author-page",
  PUBLICATIONS: "publications",
  SIGNIN_PAGE: "signin-page",
  SIGNUP_PAGE: "signup-page",
  VERIFY_EMAIL_PAGE: "verify-email-page",
  RESET_PASSWORD_PAGE: "reset-password-page",
  FORGOT_PASSWORD_PAGE: "forgot-password-page"
});

export const TAG_PAGE_URL_PREFIX = "/topic/";

export const storyFields =
  "alternative,headline,cards,subheadline,author-name,author-id,authors,updated-at,last-published-at,published-at,first-published-at,metadata,hero-image-metadata,hero-image-s3-key,slug,id,seo,story-template";

import { sortStories } from "./utils";

const authIntialState = {
  isUserLoggedIn: false,
  userObj: null
};

function authReducer(state = authIntialState, action) {
  switch (action.type) {
    case "USER_LOGGED_IN":
      return {
        ...state,
        isUserLoggedIn: action.payload.isUserLoggedIn,
        userObj: action.payload.user
      };

    default:
      return state;
  }
}

function peopleReducer(state = [], action) {
  switch (action.type) {
    case "AUTHOR_LIST":
      return action.payload;

    default:
      return state;
  }
}

function entityReducer(state = [], action) {
  switch (action.type) {
    case "ENTITY_LIST":
      return action.payload;

    default:
      return state;
  }
}

function publicationStoriesReducer(state = {}, action) {
  switch (action.type) {
    case "SECTION_PUBLICATION_STORIES":
      // key = section-id
      // value = array of stories

      const filteredStories = action.payload.value.filter(story => story["story-template"] === "publication");

      const stateObj = { ...state };
      stateObj[`${action.payload.key}`] = sortStories(filteredStories).map(story => ({ story }));

      return stateObj;

    default:
      return state;
  }
}

export default {
  authReducer,
  peopleReducer,
  entityReducer,
  publicationStoriesReducer
};

/* global width:true */

import React from "react";
import { object, array, func } from "prop-types";
import { Link } from "@quintype/components";

import "./header-menu-column.m.css";

class HeaderMenuColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  generateChildItemsDom = (childItems = [], toggleMenuBar) => {
    const childItemList = childItems.filter(item => !(item.children && item.children.length));
    const archivedItemList = childItems.find(item => item.children && item.children.length);

    const items = childItemList.map((childItem, index) => (
      <li styleName="child-menu-list-item" key={index}>
        {childItem.isExternalLink ? (
          <a href={childItem.completeUrl}>
            <span onClick={toggleMenuBar}>{childItem.title}</span>
          </a>
        ) : (
          <Link href={childItem.completeUrl}>
            <span onClick={toggleMenuBar}>{childItem.title}</span>
          </Link>
        )}
      </li>
    ));

    // Show Archive Items in gray design
    if (archivedItemList) {
      // For Space with Line
      items.push(<li styleName="empty-li" key={10000000} />); // some random number

      items.push(
        <li styleName="archived-title" key={10000001}>
          <span>Archived</span>
        </li>
      );

      archivedItemList.children.map((childItem, index) => {
        const tempItem = (
          <li styleName="archive-menu-list-item" key={`${index}-list`}>
            {childItem.isExternalLink ? (
              <a href={childItem.completeUrl}>
                <span onClick={toggleMenuBar}>{childItem.title}</span>
              </a>
            ) : (
              <Link href={childItem.completeUrl}>
                <span onClick={toggleMenuBar}>{childItem.title}</span>
              </Link>
            )}
          </li>
        );

        items.push(tempItem);
      });
    }

    return items;
  };

  render() {
    const { headerMenuColumn, childItems, toggleMenuBar } = this.props;
    const childItemsDom = this.generateChildItemsDom(childItems, toggleMenuBar);

    return childItemsDom.length ? (
      <ul styleName="list">
        {headerMenuColumn.title && (
          <li styleName="single-item" onClick={width < 768 ? this.toggleDropdown : null}>
            <span>{headerMenuColumn.title}</span>
          </li>
        )}
        {this.state.showDropdown && <ul styleName="child-menu-list">{childItemsDom}</ul>}
        {childItemsDom && <ul styleName="child-menu-list desktop">{childItemsDom}</ul>}
      </ul>
    ) : (
      headerMenuColumn.title && (
        <li styleName="single-item">
          {headerMenuColumn.isExternalLink ? (
            <a href={headerMenuColumn.completeUrl || "#"}>
              <span onClick={toggleMenuBar}>{headerMenuColumn.title}</span>
            </a>
          ) : (
            <Link href={headerMenuColumn.completeUrl || "#"}>
              <span onClick={toggleMenuBar}>{headerMenuColumn.title}</span>
            </Link>
          )}
        </li>
      )
    );
  }
}

export default HeaderMenuColumn;

HeaderMenuColumn.propTypes = {
  headerMenuColumn: object,
  childItems: array,
  toggleMenuBar: func
};

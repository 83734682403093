import React from "react";
import { SearchBox } from "@quintype/components";
import { string, func, bool, element } from "prop-types";

import Search from "../../../atoms/icons/search";
import Close from "../../../atoms/icons/close";

import "./search.m.css";

const DrawForm = ({ children }) => {
  return [
    <label styleName="search-form-label" htmlFor="searchForm" key="1">
      {children}
    </label>,
    <button type="submit" styleName="search-form-submit" key="2">
      <Search width={18} height={18} color="#476f7d" />
    </button>
  ];
};

DrawForm.propTypes = {
  children: element
};

class NavSearch extends React.PureComponent {
  render() {
    const { onToggleSearchForm, isSearchFormOpen } = this.props;

    return (
      <React.Fragment>
        {isSearchFormOpen && (
          <div styleName="search">
            <div className="container">
              <div styleName="search-form">
                <SearchBox
                  styleName="search-box"
                  template={DrawForm}
                  inputId="searchForm"
                  inputClassName="search-form-input"
                  formRef={searchForm => (this.searchForm = searchForm)}
                  onSubmitHandler={onToggleSearchForm}
                  onEscape={onToggleSearchForm}
                  placeholder="Search"
                />
                <div styleName="close-icon" onClick={onToggleSearchForm}>
                  <Close color="#476f7d" />
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

NavSearch.propTypes = {
  color: string,
  onToggleSearchForm: func,
  isSearchFormOpen: bool
};

export default NavSearch;

/* global width:true */
/* eslint no-undef: "error" */

import React from "react";
import { connect } from "react-redux";
import { array } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";

import HamburgerIcon from "../../atoms/icons/hamburger";
import HeaderMenuColumn from "../../atoms/header-menu-column";
import Search from "../../atoms/icons/search";
import Logo from "../../atoms/icons/logo";
import NavSearch from "./search";

import "./header.m.css";

class HeaderBase extends React.Component {
  state = {
    navbar: false,
    width: null,
    isSearchFormOpen: false,
    setSearchFormOpen: false,
    searchBox: false
  };

  toggleMenuBar = () => {
    this.setState({
      navbar: !this.state.navbar
    });
  };

  toggleSearchBox = () => {
    this.setState({
      searchBox: !this.state.searchBox,
      navbar: false
    });
  };

  parentMenuLinks = headerMenuLinks => headerMenuLinks.filter(item => item["parent-id"] === null);

  generateMenuColumns = headerMenuLinks =>
    this.parentMenuLinks(headerMenuLinks).map((parentItemLink, index) => (
      <HeaderMenuColumn
        key={index}
        headerMenuColumn={parentItemLink}
        childItems={parentItemLink.children}
        toggleMenuBar={this.toggleMenuBar}
      />
    ));

  componentDidMount() {
    this.setState({ width });
  }

  getNavbar = () => {
    return (
      <nav styleName="navbar">
        <ul styleName="menu-group">
          <li styleName="single-item">
            <Link href="/">Home</Link>
          </li>
          {this.generateMenuColumns(this.props.headerMenuLinks)}
          {this.state.width > 768 && (
            <button styleName="search-btn" onClick={this.toggleSearchBox}>
              <Search color="#393939" />
            </button>
          )}
        </ul>
      </nav>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div styleName="wrapper">
          <div styleName="logo-row">
            <div styleName="logo">
              <Link href="/">
                <Logo />
              </Link>
            </div>
            {this.state.width > 768 && this.getNavbar()}
            {this.state.width < 768 && (
              <React.Fragment>
                <button styleName="search-mobile" onClick={this.toggleSearchBox}>
                  <Search color="#476f7d" width={18} height={18} />
                </button>
                <HamburgerIcon onMenuToggle={this.toggleMenuBar} isNavbarOpen={this.state.navbar} />
              </React.Fragment>
            )}
          </div>
          {this.state.width < 768 && this.state.navbar && this.getNavbar()}
        </div>
        <NavSearch color="#fff" onToggleSearchForm={this.toggleSearchBox} isSearchFormOpen={this.state.searchBox} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerMenuLinks: get(state, ["qt", "data", "navigationMenu", "headerMenuLinks"], [])
  };
};

HeaderBase.propTypes = {
  headerMenuLinks: array
};

const HeaderRow = connect(mapStateToProps)(HeaderBase);

export default HeaderRow;

import React from "react";
import { string, number } from "prop-types";

const FaceBookIcon = ({ color = "#fff", width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.322 18.255c0 .59-.478 1.067-1.067 1.067h-4.923V11.84h2.511l.376-2.915h-2.887V7.062c0-.845.234-1.42 1.445-1.42h1.544V3.033a20.74 20.74 0 0 0-2.25-.115c-2.226 0-3.75 1.36-3.75 3.855v2.15H7.803v2.916h2.518v7.483H1.066A1.067 1.067 0 0 1 0 18.255V1.067C0 .477.477 0 1.066 0h17.19c.588 0 1.066.477 1.066 1.067v17.188"
    />
  </svg>
);

export function Facebook({ color = "#fff", width = 21, height = 21 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M9.917 22v-8.945H7V9.5h2.917V6.687c0-1.484.404-2.636 1.212-3.457.808-.82 1.881-1.23 3.22-1.23 1.085 0 1.97.052 2.651.156V5.32h-1.818c-.682 0-1.15.157-1.402.47-.202.26-.303.676-.303 1.25V9.5h3.22l-.455 3.555h-2.765V22h-3.56z"
      />
      <use fill={color} fillRule="evenodd" />
    </svg>
  );
}

FaceBookIcon.propTypes = {
  color: string,
  width: number,
  height: number
};

Facebook.propTypes = {
  color: string,
  width: number,
  height: number
};

export default FaceBookIcon;

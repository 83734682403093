import React from "react";
import { func, bool } from "prop-types";
import "./hamburger.m.css";

const HamburgerIcon = ({ onMenuToggle, isNavbarOpen }) => {
  return (
    <div onClick={onMenuToggle} styleName={`hamburger ${isNavbarOpen ? "is-open" : ""}`} aria-label="Close">
      <div styleName="line" />
      <div styleName="line" />
      <div styleName="line" />
    </div>
  );
};

export default HamburgerIcon;

HamburgerIcon.propTypes = {
  onMenuToggle: func,
  isNavbarOpen: bool
};

import React from "react";

const Logo = () => {
  const src =
    "https://thumbor-stg.assettype.com/ncfindia/2019-08/130ad8ea-2146-4280-8960-920a0ed77abe/NCF_Logo_with_White_BG_final.jpg?w=190&auto=format,compress";

  return <img src={src} alt="ncf-logo" width="190" />;
};

export default Logo;

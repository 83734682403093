import React from "react";
import { string, number } from "prop-types";

const Close = ({ color = "#000", width = 16, height = 16 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 12">
    <g fill="none" fillRule="nonzero">
      <path d="M-6-6h24v24H-6z" />
      <g stroke={color} strokeLinecap="square" strokeWidth="2">
        <path d="M9.39 2.11L1.61 9.89M9.39 9.89L1.61 2.11" />
      </g>
    </g>
  </svg>
);

Close.propTypes = {
  color: string,
  width: number,
  height: number
};

export default Close;

import { renderIsomorphicComponent, renderComponent } from "@quintype/framework/client/start";
import { pickComponent } from "../isomorphic/pick-component";
import Header from "../isomorphic/components/rows/header";
import Footer from "../isomorphic/components/rows/footer";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import { getUser, getAllAuthors, getEntities } from "../isomorphic/components/helper/api";
import { setUserLoggedIn, setAuthors, setEntities } from "../isomorphic/components/helper/actions";

export function preRenderApplication(store) {
  getUser()
    .then(userResponse => store.dispatch(setUserLoggedIn(true, userResponse.member)))
    .finally(() => {
      Promise.all([getAllAuthors(), getEntities()])
        .then(responses => {
          store.dispatch(setAuthors(responses[0]));
          store.dispatch(setEntities(responses[1]));
        })
        .finally(() => {
          const hydrate = { hydrate: !global.qtLoadedFromShell };
          renderComponent(Header, "header", store, hydrate);
          renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
          renderComponent(Footer, "footer", store, hydrate);
        });
    });
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}

import React from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import get from "lodash/get";

import FaceBookIcon from "../atoms/icons/fb";
import InstagramIcon from "../atoms/icons/instagram";
import TwitterIcon from "../atoms/icons/twitter";

import "./social-share-icons.m.css";

const SocialShareIcons = ({ socialLinks = {} }) => {
  const list = [];
  list.push(
    {
      componentName: FaceBookIcon,
      url: socialLinks && socialLinks["facebook-url"]
    },
    {
      componentName: TwitterIcon,
      url: socialLinks && socialLinks["twitter-url"]
    },
    {
      componentName: InstagramIcon,
      url: socialLinks && socialLinks["instagram-url"]
    }
  );

  return (
    <ul styleName="icon-list" className="social-media-icon-wrapper">
      {list.map((item, index) => {
        const SocialIcons = item.componentName;

        return (
          item.url && (
            <li styleName="icon-item" key={index}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <SocialIcons />
              </a>
            </li>
          )
        );
      })}
    </ul>
  );
};

SocialShareIcons.propTypes = {
  socialLinks: object,
  socialUrls: object
};

function mapStateToProps(state) {
  return {
    socialLinks: get(state, ["qt", "config", "social-links"], {}),
    socialUrls: get(state, ["qt", "config", "publisher-attributes", "socialUrls"], {})
  };
}

export default connect(
  mapStateToProps,
  () => ({})
)(SocialShareIcons);

import { format } from "date-fns";
import get from "lodash/get";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  let k = 1024;
  let dm = decimals <= 0 ? 0 : decimals || 2;
  let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const getTime = unixTimestamp => {
  return format(new Date(unixTimestamp), "MMMM DD YYYY, h:mm A");
};

// Validate the Email
export function isValidEmail(email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!re.test(email)) return false;
  if (email.length > 150 || email.length < 6) return false;
  return true;
}

// Filter the authors whose status is 'Alumnus'
export function filterAlumnusAuthors(originalList, authors, showAlumnus = false) {
  const list = [];

  authors.forEach(item => {
    const authorObj = originalList.find(c => c.id === item.id);

    if (showAlumnus && authorObj && authorObj.metadata && authorObj.metadata.status === "Alumnus") {
      list.push({
        ...authorObj,
        contributorRoleName: item["contributor-role"] ? item["contributor-role"].name : null,
        isAlumnus: true
      });
    } else if (authorObj && authorObj.metadata && authorObj.metadata.status !== "Alumnus") {
      list.push({
        ...authorObj,
        contributorRoleName: item["contributor-role"] ? item["contributor-role"].name : null
      });
    }
  });

  return list;
}

// Sort the stories based on publicationyear in story-attributes
export function sortStories(stories) {
  let yearString;

  const sortedStories = stories.map(item => {
    const story = item.story ? item.story : item;

    // set -1 year if year does't exist
    yearString = get(story, ["metadata", "story-attributes", "publicationyear", "0", "name"], "-1");

    if (yearString === "-1") {
      yearString = get(story, ["metadata", "story-attributes", "publication-year", "0", "name"], "-1");
    }

    item.year = yearString === "In press" ? 10000 : parseInt(yearString); // Set higher number so that 'In press' records shows up on top

    return item;
  });

  return sortedStories.sort((a, b) => b.year - a.year);
}

export function generateHostStoryUrl(story = {}) {
  if (global.location) {
    return `${global.location.origin}/${story.slug}`;
  }
}

export function getEntityColorCode(name) {
  let color = null;

  switch (name) {
    case "Art & Literary":
      color = "#c90";
      break;
    case "Book":
      color = "#6f385a";
      break;
    case "Book Chapter":
      color = "#5d577b";
      break;
    case "Book Review":
      color = "#a45756";
      break;
    case "Conference Proceedings":
      color = "#326699";
      break;
    case "Dataset":
      color = "#326699";
      break;
    case "Journal Article":
      color = "#2c6b63";
      break;
    case "Newsletter":
      color = "#c90";
      break;
    case "Popular Article":
      color = "#98393a";
      break;
    case "Poster":
      color = "#a45756";
      break;
    case "Report":
      color = "#4e623c";
      break;
    case "Thesis":
      color = "#83852e";
      break;
    case "Working Paper":
      color = "#ba6424";
      break;

    default:
      break;
  }

  return color;
}

export function getFormattedAuthors(authors) {
  const list = [];
  const obj = authors.reduce(function(r, a) {
    r[a.contributorRoleName] = r[a.contributorRoleName] || [];
    r[a.contributorRoleName].push(a);
    return r;
  }, Object.create(null));

  for (const key in obj) {
    list.push({
      heading: key,
      list: obj[key]
    });
  }

  return list;
}

import React from "react";
import { string, number } from "prop-types";

const InstagramIcon = ({ color = "#fff", width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path
      fill={color}
      fillRule="evenodd"
      d="M17.003 0H2.293A2.29 2.29 0 0 0 0 2.288v14.72a2.291 2.291 0 0 0 2.294 2.288h14.709a2.291 2.291 0 0 0 2.293-2.288V2.288A2.29 2.29 0 0 0 17.003 0zm-3.134 3.015c0-.333.27-.603.603-.603h1.81c.332 0 .602.27.602.603v1.81c0 .332-.27.602-.603.602h-1.809a.603.603 0 0 1-.603-.603v-1.81zM9.671 5.968a3.707 3.707 0 0 1 3.71 3.703 3.707 3.707 0 0 1-3.71 3.702A3.706 3.706 0 0 1 5.96 9.67a3.707 3.707 0 0 1 3.71-3.703zm7.816 10.916c0 .333-.27.603-.603.603H2.412a.603.603 0 0 1-.603-.603V7.84H4.22c-.314.452-.42 1.296-.42 1.832 0 3.228 2.633 5.856 5.87 5.856 3.236 0 5.87-2.628 5.87-5.856 0-.536-.076-1.367-.466-1.832h2.412v9.045z"
    />
  </svg>
);

InstagramIcon.propTypes = {
  color: string,
  width: number,
  height: number
};

export default InstagramIcon;
